import { Injectable } from "@angular/core";

@Injectable()
export class MGISConstants {

    AutomaticPaymentInfo = {
        title: 'About Automatic Payments',
        info: [
            `Use the Automatic Payments option to set up automatic withdrawals from your bank account for the total due on your account.
            Simply choose a billing account to pay from this bank account, and turn on Automatic Payments.
            The balance on a Billing Account will be presented by us to your bank on approximately the 5th of each month.
            You should see this amount withdrawn from your bank account by the 7th or 8th of the month.`,
            `MGIS will send you a detailed statement each month listing all of the charges and total due.
            You should receive this statement on approximately the 1st of the month due.
            Please review this statement and return it with any changes OR make your changes online.
            The changes will be reflected on the following month's statement.
            If you would like to suspend your Recurring Payments please call the account managers shown on your statement or contact Korinda Matson at (801) 990-2400 ext 285`
        ]
    };

    BankAccountTypes = [
        { id: 'C', text: 'Checking' },
        { id: 'S', text: 'Savings' }
    ];

    BenefitType = {
        Force: 'F',
        Elect: 'E',
        Opt: 'O'
    };

    ChangeCategories = [
        { Name: 'Member Profile', Code: 'P' },
        { Name: 'Member Employment', Code: 'E' },
        { Name: 'Dependents', Code: 'D' },
        { Name: 'Coverages', Code: 'C' }
    ];

    Contact = {
        HelpPhone: '1 (800) 969-6447'
    };

    CoverageCategories = [
        'Employer-Paid',
        'Employee-Paid',
        'Shared'
    ];

    DependentTypes = [
        { id: 'S', text: 'Spouse' },
        { id: 'C', text: 'Child' },
        { id: 'O', text: 'Other' }
    ];

    DisplayNames = {
        member: {
            CompassId: 'ID',
            Num: 'Member Number',

            LastName: 'Last Name',
            FirstName: 'First Name',
            BirthDate: 'Birth Date',
            TobaccoUser: 'Tobacco User',

            HireDate: 'Hire Date',
            TermDate: 'Termination Date',
            HoursPerWeek: 'Hours Per Week',
            WageRate: 'Salary',
            WageType: 'Salary Type',
            JobTitle: 'Job Title',

            ClassNum: 'Class Number',
            LocNum: 'Location Number',
            DeptNum: 'Department Number',
            TierNum: 'Tier Number',
            Leave: 'On Leave',
            LeaveDate: 'Leave Date',
            RetirementDate: 'Retirement Date',
            CovStatus: 'Coverage Status'
        },
        dependent: {
            CompassId: 'ID',
            LastName: 'Last Name',
            FirstName: 'First Name',
            BirthDate: 'Birth Date',
            TypeCode: 'Type Code',

            FullTimeStudent: 'Full Time Student',
            TobaccoUser: 'Tobacco User'
        },
        coverage: {
            SelectedBenefitAmount: 'Selected Benefit Amount',
            EffectiveDate: 'Effective Date',
            EffectiveDateModifiedReason: 'Reason for Modified Effective Date',
            SignatureDate: 'Signature Date'
        }
    };

    DocTypeConversion = [
            ['Manual',  'M' ],
            ['Enrollment Form',  'EF' ],
            ['EOI',  'E' ],
            ['Beneficiary/Assignment',  'BA' ],
            ['Sabbatical Leave',  'SL' ],
            ['Claim Form',  'CF' ],
            ['Conversion',  'CO' ],
            ['Portability',  'PO' ],
            ['Contract',  'CT' ],
            ['Benefit Booklet', 'B'],
            ['Employee Benefit Booklet', 'B'],
            ['Manual', 'M'],
            ['Administration Manual', 'M'],
            ['Flyer',  'FL' ],
            ['Emergency Travel Assistance',  'ET' ],
            ['Identity Theft',  'IT' ],
            ['EAP',  'EP' ],
            ['Schedule A',  'SA' ]
        ]

    DocumentTypes = {
        policy: {
            title: 'Policy Forms',
            sections: [
                {
                    title: 'Administration',
                    fileTypes: [
                        { type: 'Manual', code: 'M' }
                    ]
                },
                {
                    title: 'Enrollments',
                    fileTypes: [
                        { type: 'Enrollment Form', code: 'EF' },
                        { type: 'EOI', code: 'E' }
                    ]
                },
                {
                    title: 'Change Forms',
                    fileTypes: [
                        { type: 'Beneficiary/Assignment', code: 'BA' },
                        { type: 'Sabbatical Leave', code: 'SL' }
                    ]
                },
                {
                    title: 'Claim Forms',
                    fileTypes: [
                        { type: 'Claim Form', code: 'CF' }
                    ]
                },
                {
                    title: 'Continuation of Coverage',
                    fileTypes: [
                        { type: 'Conversion', code: 'CO' },
                        { type: 'Portability', code: 'PO' }
                    ]
                }
            ]
        },
        contracts: {
            title: 'Contracts & Booklets',
            sections: [
                {
                    title: 'Contracts',
                    fileTypes: [
                        { type: 'Contract', code: 'CT' }
                    ]
                },
                {
                    title: 'Booklets',
                    fileTypes: [
                        { type: 'Benefit Booklet', code: 'B' }
                    ]
                },
                {
                    title: 'Administration',
                    fileTypes: [
                        { type: 'Manual', code: 'M' }
                    ]
                }
            ]
        },
        benefits: {
            title: 'Benefit Summaries'
        },
        census: {
            title: 'Census & Premium Deductions'
        },
        brochures: {
            title: 'Brochures & Flyers',
            sections: [
                {
                    title: 'Flyers',
                    fileTypes: [
                        { type: 'Flyer', code: 'FL' }
                    ]
                },
                {
                    title: 'Emergency Travel Assistance',
                    fileTypes: [
                        { type: 'Emergency Travel Assistance', code: 'ET' }
                    ]
                },
                {
                    title: 'Identity Theft',
                    fileTypes: [
                        { type: 'Identity Theft', code: 'IT' }
                    ]
                },
                {
                    title: 'Employee Assistance Program (EAP)',
                    fileTypes: [
                        { type: 'EAP', code: 'EP' }
                    ]
                }
            ]
        },
        other: {
            title: 'Other Documents',
            sections: [
                {
                    title: 'Annual Tax forms',
                    fileTypes: [
                        { type: 'Schedule A', code: 'SA' }
                    ]
                }
            ]
        },
    };

    EnrollmentReasons = [
        { Value: 'New Hire', Available: true },
        { Value: 'Rehire', Available: true },
        { Value: 'Class Change', Available: false },
        { Value: 'Newly Eligible Due to Increased Hours', Available: true },
        { Value: 'Change in Employment Status', Available: true },
        { Value: 'Life Event', Available: true },
        { Value: 'Other', Available: true }
    ];

    EnrollmentCompletionStatuses = {
        NotStarted: "Not Started",
        InProgress: "In Progress",
        Completed: "Completed"
    };

    SurplusLinesApplicationQuestions = [
        {
            Section: 'A', SectionName: 'Health Status', SectionSubText: '', Indent: 1, Step: 'status', QuestionNum: '2',
            QuestionText: 'For the time period commencing 180 days prior to the date of this application:',
            Show: 'Always',
            AnswerType: 'None',
        },
        {
            Section: 'A', Step: 'status', SectionName: 'Health Status', SectionSubText: '', Indent: 2, QuestionNum: '2.a', NeedsAnswer: true,
            QuestionText: 'Have you been unable to work continuously on a full-time basis, even for one day, in the usual and customary manner performing all the duties of your occupation?',
            Show: 'Always',
            AnswerType: 'Boolean',
            TrueHTML: 'You <b>have been</b> unable to work continuously on a full-time basis, for one day or more, in the usual and customary manner performing all the duties of your occupation.',
            FalseHTML: 'You <b>have not been</b> unable to work continuously on a full-time basis, even for one day, in the usual and customary manner performing all the duties of your occupation.',
            ExplanationQuestions: [
                { QuestionText: 'Reason for missed work', Indent: 3, ExplanationQuestionNum: '1', Show: 'Yes', AnswerType: 'Free Form' },
                { QuestionText: 'Duration of absence from work', Indent: 3, ExplanationQuestionNum: '2', Show: 'Yes', AnswerType: 'Free Form' }
            ]
        },
        {
            Section: 'A', Step: 'status', SectionName: 'Health Status', SectionSubText: '', Indent: 2, QuestionNum: '2.b', NeedsAnswer: true,
            QuestionText: 'Have you been homebound or hospitalized due to an accident or sickness?',
            Show: 'Always',
            AnswerType: 'Boolean',
            TrueHTML: 'You <b>have been</b> homebound or hospitalized due to an accident or sickness.',
            FalseHTML: 'You <b>have not been</b> homebound or hospitalized due to an accident or sickness.',
            ExplanationQuestions: [
                { QuestionText: 'Nature of accident or sickness', Indent: 3, ExplanationQuestionNum: '1', Show: 'Yes', AnswerType: 'Free Form' },
                { QuestionText: 'Length of time homebound or hospitalized', Indent: 3, ExplanationQuestionNum: '2', Show: 'Yes', AnswerType: 'Free Form' },
                { QuestionText: 'Current status of accident or sickness', Indent: 3, ExplanationQuestionNum: '3', Show: 'Yes', AnswerType: 'Free Form' }
            ]
        },
        {
            Section: 'A', Step: 'status', SectionName: 'Health Status', SectionSubText: '', Indent: 2, QuestionNum: '2.c', NeedsAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Have you received treatment (including medication) or been under the care of a physician or other medical professional for an injury or sickness?',
            TrueHTML: 'You <b>have received</b> treatment and/or medication or been under the care of a physician or other medical professional for an injury or sickness.',
            FalseHTML: 'You <b>have not received</b> treatment or medication or been under the care of a physician or other medical professional for an injury or sickness.',
            ExplanationQuestions: [
                { QuestionText: 'Medications taken', Indent: 3, ExplanationQuestionNum: '1', Show: 'Yes', AnswerType: 'Free Form' },
                { QuestionText: 'Dosage and frequency', Indent: 3, ExplanationQuestionNum: '2', Show: 'Yes', AnswerType: 'Free Form' },
                { QuestionText: 'Injury or sickness treated or under care for', Indent: 3, ExplanationQuestionNum: '3', Show: 'Yes', AnswerType: 'Free Form' },
                { QuestionText: 'Diagnosis', Indent: 3, ExplanationQuestionNum: '4', Show: 'Yes', AnswerType: 'Free Form' },
                { QuestionText: 'Ongoing treatment', Indent: 3, ExplanationQuestionNum: '5', Show: 'Yes', AnswerType: 'Free Form' }
            ]
        },
        {
            Section: 'E', Step: 'other', SectionName: 'Other Insurance', SectionSubText: '', Indent: 1, QuestionNum: '4', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Have you ever had life, health, or accident insurance declined, postponed, cancelled, rated, or modified or renewal or reinstatement of such insurance refused?',
            TrueHTML: 'You <b>have</b> had life, health, or accident insurance declined, postponed, cancelled, rated, or modified or renewal or reinstatement of such insurance refused.',
            FalseHTML: 'You <b>have never</b> had life, health, or accident insurance declined, postponed, cancelled, rated, or modified or renewal or reinstatement of such insurance refused.',
            ExplanationQuestions: [{ QuestionText: 'Please Explain', Indent: 1, ExplanationQuestionNum: '1', Show: 'Yes', AnswerType: 'Free Form' },]
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 1, QuestionNum: '2',
            Show: 'Always',
            AnswerType: 'None',
            QuestionText: 'In the past five years, have you:',
            TrueHTML: 'In the past five years, you <b>have</b>:',
            FalseHTML: 'In the past five years, you <b>have not</b>:'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '2.a', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Had transplant surgery, other surgery, injuries or been treated in a hospital?',
            TrueHTML: 'Had transplant surgery, other surgery, injuries or been treated in a hospital',
            FalseHTML: 'Had transplant surgery, other surgery, injuries or been treated in a hospital'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '2.b', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Been treated for alcoholism or advised by a physician to change your drinking habits?',
            TrueHTML: 'Been treated for alcoholism or advised by a physician to change your drinking habits',
            FalseHTML: 'Been treated for alcoholism or advised by a physician to change your drinking habits',
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '2.c', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Used heroin, marijuana, cocaine, LSD, amphetamines, or any other narcotic?',
            TrueHTML: 'Used heroin, marijuana, cocaine, LSD, amphetamines, or any other narcotic',
            FalseHTML: 'Used heroin, marijuana, cocaine, LSD, amphetamines, or any other narcotic'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '2.d', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Been off work for more than five consecutive days due to illness or injury?',
            TrueHTML: 'Been off work for more than five consecutive days due to illness or injury',
            FalseHTML: 'Been off work for more than five consecutive days due to illness or injury',
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '2.e', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Lost 20 lbs. or more over a 12-month period?',
            TrueHTML: 'Lost 20 lbs. or more over a 12-month period',
            FalseHTML: 'Lost 20 lbs. or more over a 12-month period'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 1, QuestionNum: '3',
            Show: 'Always',
            AnswerType: 'None',
            QuestionText: 'In the past five years, have you been diagnosed with, treated for or had any symptoms relating to any of the conditions listed below?',
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.a', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Dizzy spells, epilepsy, a nervous or neurological disorder, migraines or a mental disorder',
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.b', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Asthma, bronchitis, emphysema, chronic cough, shortness of breath, Chronic Obstructive Pulmonary Disease (COPD) or lung disorder'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.c', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Abnormal blood pressure, chest pain, heart murmur, heart disease or heart attack'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.d', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Ulcer, liver disorder, colitis, diarrhea or any complaint of the digestive organs',
            TrueHTML: 'Ulcer, liver disorder, colitis, diarrhea or some complaint of the digestive organs'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.e', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Arthritis, gout, rheumatism, back disorder, disc disease or joint or bone disorder'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.f', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Cancer tumor, enlarged glands, enlarged lymph nodes or lupus'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.g', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Sugar in urine, diabetes, kidney or bladder disorder'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.h', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Acquired Immune Deficiency Syndrome (AIDS) or AIDS related Complex (ARC) or tested positive for the Human Immunodeficiency Virus (HIV)'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.i', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Anemia, blood vessel disease, bleeding or any other blood disease or disorder',
            TrueHTML: 'Anemia, blood vessel disease, bleeding or another blood disease or disorder'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.j', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Disorders of the eyes or ears'
        },
        {
            Section: 'B', Step: 'history', SectionName: 'Health History', SectionSubText: '', Indent: 2, QuestionNum: '3.k', NeedsAnswer: true, NeedsConditionAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Chronic fatigue, chronic pain, fibromyalgia, lupus or any other autoimmune disorder',
            TrueHTML: 'Chronic fatigue, chronic pain, fibromyalgia, lupus or another autoimmune disorder'
        },
        {
            Section: 'C', Step: 'activities', SectionName: 'High-Risk Activities', SectionSubText: '', Indent: 1, QuestionNum: '5',
            Show: 'Always',
            AnswerType: 'None',
            QuestionText: 'Do you engage in any of the following activities?',
            TrueHTML: 'You <b>do</b> engage in the following activities:',
            FalseHTML: 'You <b>do not</b> engage in the following activities:'
        },
        {
            Section: 'C', Step: 'activities', SectionName: 'High-Risk Activities', SectionSubText: '', Indent: 2, QuestionNum: '5.a', NeedsAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Skydiving'
        },
        {
            Section: 'C', Step: 'activities', SectionName: 'High-Risk Activities', SectionSubText: '', Indent: 2, QuestionNum: '5.b', NeedsAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Scuba diving'
        },
        {
            Section: 'C', Step: 'activities', SectionName: 'High-Risk Activities', SectionSubText: '', Indent: 2, QuestionNum: '5.c', NeedsAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Vehicle or boat racing'
        },
        {
            Section: 'C', Step: 'activities', SectionName: 'High-Risk Activities', SectionSubText: '', Indent: 2, QuestionNum: '5.d', NeedsAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Piloting an aircraft'
        },
        {
            Section: 'D', Step: 'license', SectionName: 'Medical License', SectionSubText: '', Indent: 2, QuestionNum: '3.a', NeedsAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Had your license to practice medicine or your permit to prescribe drugs denied, revoked, suspended, voluntarily suspended, or otherwise investigated or limited?',
            TrueHTML: 'Your license to practice medicine or your permit to prescribe drugs <b>is or has been</b> denied, revoked, suspended, voluntarily suspended, or otherwise investigated or limited.',
            FalseHTML: 'Your license to practice medicine or your permit to prescribe drugs <b>has never been</b> denied, revoked, suspended, voluntarily suspended, or otherwise investigated or limited.'
        },
        {
            Section: 'D', Step: 'license', SectionName: 'Medical License', SectionSubText: '', Indent: 2, QuestionNum: '3.b', NeedsAnswer: true,
            Show: 'Always',
            AnswerType: 'Boolean',
            QuestionText: 'Appeared before, been investigated by, or entered into any consent agreement with any formal hospital committee, state licensing Board, Board of Medical Examiners, or other medical review committee or do you have any open professional liability claims?',
            TrueHTML: 'You <b>have</b> appeared before, been investigated by, or entered into a consent agreement with a formal hospital committee, state licensing Board, Board of Medical Examiners, or other medical review committee, or have one or more open professional liability claims.',
            FalseHTML: 'You <b>have not</b> appeared before, been investigated by, or entered into any consent agreement with any formal hospital committee, state licensing Board, Board of Medical Examiners, or other medical review committee, and you do not have any open professional liability claims.',
        },
        // {
        //     Section: 'B', Step: 'license', QuestionNum: '6', NeedsAnswer: true,
        //     QuestionText: 'Has your license to practice medicine or your permit to prescribe drugs ever been denied, revoked, suspended, voluntarily suspended, or otherwise investigated or limited?',
        //     TrueHTML: 'Your license to practice medicine or your permit to prescribe drugs <b>is or has been</b> denied, revoked, suspended, voluntarily suspended, or otherwise investigated or limited.',
        //     FalseHTML: 'Your license to practice medicine or your permit to prescribe drugs <b>has never been</b> denied, revoked, suspended, voluntarily suspended, or otherwise investigated or limited.'
        // },
        // {
        //     Section: 'B', Step: 'license', QuestionNum: '7', NeedsAnswer: true,
        //     QuestionText: 'Have you ever appeared before, been investigated by, or entered into any consent agreement with any formal hospital committee, state licensing Board, Board of Medical Examiners, or other medical review committee?',
        //     TrueHTML: 'You <b>have</b> appeared before, been investigated by, or entered into a consent agreement with a formal hospital committee, state licensing Board, Board of Medical Examiners, or other medical review committee.',
        //     FalseHTML: 'You <b>have not</b> appeared before, been investigated by, or entered into any consent agreement with any formal hospital committee, state licensing Board, Board of Medical Examiners, or other medical review committee.',
        // },
    ];

    SurplusLinesBenefitType = {
        Monthly: 'Monthly Benefit',
        LumpSum: 'Lump-sum Benefit',
        Combination: 'Combined Monthly & Lump-sum Benefits'
    };

    SurplusLinesDocumentStatus = {
        NotSubmitted: { Name: 'Not Submitted', Code: 'N' },
        Submitted: { Name: 'Submitted', Code: 'S' }
    };

    SurplusLinesGITypeCodes = {
        NoGI: 'N',
        SimplifiedGI: 'S',
        FullGI: 'F'
    };

    SurplusLinesOpportunityStatus = {
        Incomplete: 'Incomplete',
        Submitted: 'Submitted',
        Expired: 'Expired',
        Declined: 'Declined'
    };

    SurplusLinesPolicyStatus = {
        Pending: 'P',
        Current: 'C',
        Expired: 'E'
    };

    SurplusLinesPolicyType = {
        Common: 'Common Benefits',
        Varied: 'Varied Benefits'
    };

    SurplusLinesQuoteStatus = {
        InProgress: 'In Progress',
        Saved: 'Saved for Later',
        Invited: 'Applicant Invited',
        Accepted: 'Invitation Accepted'
    };

    AuthKeys = {
        AccessTokenKey: 'access_token',
        AuthKey: 'authData',
        PermissionsKey: 'permissions',
        ClientKey: 'selectedClient',
        ClientHashKey: 'selectedClient_hash',
        ExpiresKey: 'expires_in',
        PortalKey: 'portal',
        ProducerKey: 'selectedProducer',
        SurplusLinesAccountKey: 'surplusLinesAccount',
        SurplusLinesLoggedIn: 'surplusLinesLoggedIn',
        SessionTimeoutKey: 'sessionTimeout'
    };

    GroupBenefitsStorageKeys = {
        DashboardInfo: 'group_benefits_dashboard_info'
    };

    LogLevels = [
        'Info',
        'Warn',
        'Error',
        'Fatal'
    ];

    SurplusLinesStorageKeys = {
        ExistingUser: 'existing_user'
    }

    UpdateStatus = {
        Submitted: 'Submitted',
        Processed: 'Processed',
        Declined: 'Declined',
        Cancelled: 'Cancelled'
    };

    UpdateType = {
        Add: { Type: 'Add', Code: 'A' },
        Edit: { Type: 'Edit', Code: 'E' },
        Terminate: { Type: 'Terminate', Code: 'T' }
    };

    Modes = {
        View: 'view',
        Add: 'add',
        Edit: 'edit',
        Enroll: 'enroll'
    };

    PaymentStatus = {
        Pending: 'Pending',
        Processed: 'Processed',
        Cancelled: 'Cancelled',
        Returned: 'Returned'
    };

    Portals = {
        MGISAdmin: { Name: 'MGIS Administration', Display: 'MGIS Administration' },
        GroupBenefits: { Name: 'Group Benefits Portal', Display: 'Employee Benefits Administration' },
        SurplusLines: { Name: 'Surplus Lines Portal', Display: 'Specialty Products' },
        Producer: { Name: 'Producer Portal', Display: 'Broker' },
        MedTravel: { Name: 'MedTravel Portal', Display: 'Specialty Products' }
    };

    RehireReasons = [
        'Re-Employment',
        'New Eligibility Due to Increased Working Hours',
        'New Eligibility Due to Class Change'
    ];

    Roles = {
        MGISSuperUser: 'MGIS Administration | Super User',
        MGISAccountManager: 'MGIS Administration | Account Manager',
        MGISBrokerServices: 'MGIS Administration | Broker Services',

        GroupBenefitsAdmin: 'Group Benefits Portal | Group Admin',

        SurplusLinesApplicant: 'Surplus Lines Portal | Applicant',
        SurplusLinesBusinessContact: 'Surplus Lines Portal | Business Contact',
        SurplusLinesInsured: 'Surplus Lines Portal | Insured',
        SurplusLinesPolicyOwner: 'Surplus Lines Portal | Policy Owner',

        ProducerAdmin: 'Producer Portal | Producer Admin',

        MedTravelApplicant: 'MedTravel Portal | Applicant'
    };

    SalaryTypes = [
        { id: 'AN', text: 'Annually', common: true },
        { id: 'BM', text: 'Bi-monthly', common: false },
        { id: 'BW', text: 'Bi-weekly', common: true },
        { id: 'DA', text: 'Daily', common: false },
        { id: 'HO', text: 'Hourly', common: true },
        { id: 'MO', text: 'Monthly', common: true },
        { id: 'QU', text: 'Quarterly', common: false },
        { id: 'SM', text: 'Semi-monthly', common: true },
        { id: 'WE', text: 'Weekly', common: true }
    ];

    States = [
        { id: 'AL', text: 'Alabama' },
        { id: 'AK', text: 'Alaska' },
        { id: 'AZ', text: 'Arizona' },
        { id: 'AR', text: 'Arkansas' },
        { id: 'CA', text: 'California' },
        { id: 'CO', text: 'Colorado' },
        { id: 'CT', text: 'Connecticut' },
        { id: 'DE', text: 'Delaware' },
        { id: 'DC', text: 'District Of Columbia' },
        { id: 'FL', text: 'Florida' },
        { id: 'GA', text: 'Georgia' },
        { id: 'HI', text: 'Hawaii' },
        { id: 'ID', text: 'Idaho' },
        { id: 'IL', text: 'Illinois' },
        { id: 'IN', text: 'Indiana' },
        { id: 'IA', text: 'Iowa' },
        { id: 'KS', text: 'Kansas' },
        { id: 'KY', text: 'Kentucky' },
        { id: 'LA', text: 'Louisiana' },
        { id: 'ME', text: 'Maine' },
        { id: 'MD', text: 'Maryland' },
        { id: 'MA', text: 'Massachusetts' },
        { id: 'MI', text: 'Michigan' },
        { id: 'MN', text: 'Minnesota' },
        { id: 'MS', text: 'Mississippi' },
        { id: 'MO', text: 'Missouri' },
        { id: 'MT', text: 'Montana' },
        { id: 'NE', text: 'Nebraska' },
        { id: 'NV', text: 'Nevada' },
        { id: 'NH', text: 'New Hampshire' },
        { id: 'NJ', text: 'New Jersey' },
        { id: 'NM', text: 'New Mexico' },
        { id: 'NY', text: 'New York' },
        { id: 'NC', text: 'North Carolina' },
        { id: 'ND', text: 'North Dakota' },
        { id: 'OH', text: 'Ohio' },
        { id: 'OK', text: 'Oklahoma' },
        { id: 'OR', text: 'Oregon' },
        { id: 'PA', text: 'Pennsylvania' },
        { id: 'RI', text: 'Rhode Island' },
        { id: 'SC', text: 'South Carolina' },
        { id: 'SD', text: 'South Dakota' },
        { id: 'TN', text: 'Tennessee' },
        { id: 'TX', text: 'Texas' },
        { id: 'UT', text: 'Utah' },
        { id: 'VT', text: 'Vermont' },
        { id: 'VA', text: 'Virginia' },
        { id: 'WA', text: 'Washington' },
        { id: 'WV', text: 'West Virginia' },
        { id: 'WI', text: 'Wisconsin' },
        { id: 'WY', text: 'Wyoming' }
    ];

    Iterations = {
        1: 'first',
        2: 'second',
        3: 'third',
        4: 'fourth',
        5: 'fifth',
        6: 'sixth',
        7: 'seventh',
        8: 'eighth',
        9: 'ninth'
    };

    MedTravelEstimateSteps = {
        intro: 'intro',
        summary: 'summary',
        trip: {
            country: 'trip-country',
            duration: 'trip-duration',
            add: 'trip-should-add',
            summary: 'trip-summary'
        },
        group: {
            intro: 'group-intro',
            specialty: 'group-specialty',
            individuals: 'group-individuals',
            duration: 'group-duration',
            activity: {
                name: 'activity-type',
                duration: 'activity-duration',
                add: 'activity-should-add'
            },
            location: {
                type: 'location-type',
                duration: 'location-duration',
                add: 'location-should-add'
            },
            summary: 'group-summary',
            add: 'group-should-add'
        },
        coverage: {
            intro: 'coverage-intro',
            date: 'coverage-date',
            liability: {
                limit: 'coverage-limit',
                type: 'coverage-type',
                deductible: 'coverage-deductible',
                parties: 'coverage-parties',
                jurisdiction: 'coverage-jurisdiction'
            },
            expenses: 'coverage-expenses',
            crisis: 'coverage-crisis',
            kidnap: 'coverage-kidnap',
            summary: 'coverage-summary'
        }
    };

    MedTravelStorageKeys = {
        model: 'medTravelEstimateModel',
        started: 'medTravelEstimateStarted',
        step: 'medTravelEstimateStep'
    };

    MedTravelQuestions = [
        { id: 1, section: 1, expectedAnswer: true, question: 'Do each of the above-named individuals carry a full and valid professional license in their country and practice state to practice the clinical activities to be undertaken during the period of travel?' },
        { id: 2, section: 1, expectedAnswer: false, question: 'Have any of the above-named individuals ever had a professional license denied, revoked, suspended, voluntarily suspended, or otherwise investigated or limited in any way?' },
        { id: 3, section: 1, expectedAnswer: false, question: 'Do any of the above-named individuals intend to perform procedures outside their customary scope of practice or medical specialty?' },
        { id: 4, section: 1, expectedAnswer: false, question: 'Do any of the above-named individuals have any regular medication requirements or pre-existing medical conditions?' },
        { id: 5, section: 1, expectedAnswer: false, question: 'During the last 10 years, has any claim been made, defended or settled, or has any malpractice or negligence been alleged against any above-named individual either in their usual country of residence or abroad?' },
        { id: 6, section: 1, expectedAnswer: false, question: 'Are there any circumstances which may result in a claim against any above-named individual?' },
        { id: 7, section: 1, expectedAnswer: false, question: 'Has any above-named individual ever been subject to disciplinary proceedings for professional misconduct?' },
        { id: 8, section: 1, expectedAnswer: false, question: 'Has any above-named individual ever experienced any Kidnap or Ransom related threats or incidents?' },
        { id: 9, section: 2, expectedAnswer: true, question: 'Have all necessary licenses and approvals been obtained from the appropriate local authorities?' },
        { id: 10, section: 2, expectedAnswer: true, question: 'Have local health standards and best practices of the appropriate areas of clinical practice been identified?' },
        { id: 11, section: 2, expectedAnswer: true, question: 'Have all participants undertaken cultural sensitivity training appropriate to the travel destination?' }
    ];

    Quotes = [
        { quote: 'Whatever you are, be a good one.', author: 'Abraham Lincoln' },
        { quote: 'Life is for service.', author: 'Fred Rogers' },
        { quote: 'In real life, the most practical advice for leaders is not to treat pawns like pawns, nor princes like princes, but all persons like persons.', author: 'James MacGregor Burns, Leadership Scholar' },
        { quote: "I have learned to imagine an invisible sign around each person’s neck that says, 'Make me feel important!'", author: 'Mary Kay Ash, Founder, Mary Kay Cosmetics' },
        { quote: "You are what you do, not what you say you'll do.", author: 'Carl Jung' },
        { quote: 'The magic formula that successful businesses have discovered is to treat customers like guests and employees like people.', author: 'Tom Peters, Speaker and Author' },
        { quote: 'To earn the respect (and eventually love) of your customers, you first have to respect those customers. That is why Golden Rule behavior is embraced by most of the winning companies.', author: 'Colleen Barrett, Southwest Airlines President Emerita' },
        { quote: "Ask your customers to be part of the solution, and don't view them as part of the problem.", author: 'Alan Weiss, Author "Million Dollar Consulting"' },
        { quote: 'Strive not to be a success, but rather to be of value.', author: 'Albert Einstein' },
        { quote: 'To keep a customer demands as much skill as to win one.', author: 'American Proverb' },
        { quote: 'To give without any reward, or any notice, has a special quality of its own.', author: 'Anne Morrow Lindeberg, Author and Aviator' },
        { quote: 'There is a spiritual aspect to our lives — when we give, we receive — when a business does something good for somebody, that somebody feels good about them!', author: "Ben Cohen, Co-Founder Ben & Jerry's" },
        { quote: 'Well done is better than well said.', author: 'Benjamin Franklin' },
        { quote: 'Remember not only to say the right thing in the right place, but far more difficult still, to leave unsaid the wrong thing at the tempting moment.', author: 'Benjamin Franklin' },
        { quote: 'Kind words do not cost much. Yet they accomplish much.', author: 'Blaise Pascal, Mathematician, physicist and inventor' },
        { quote: 'The difference between "try" and "triumph" is just a little "umph."', author: 'Bonnie Przybylski, Projects Manager W.R. Grace' },
        { quote: "Successful people are always looking for opportunities to help others. Unsuccessful people are always asking, 'What's in it for me?'", author: 'Brian Tracy, Author and Motivational Speaker' },
        { quote: 'A man without a smiling face must not open a shop.', author: 'Chinese Proverb' },
        { quote: "You can buy a person's time; you can buy their physical presence at a given place; you can even buy a measured number of their skilled muscular motions per hour. But you cannot buy enthusiasm ... You cannot buy loyalty ... You cannot buy the devotion of hearts, minds, or souls. You must earn these.", author: 'Clarence Francis, Former Chairman of the Board General Foods Corporation' },
        { quote: 'Care more than others think wise. Risk more than others think safe. Dream more than others think practical. Expect more than others think possible.', author: 'Claude Bissell, Author and Educator' },
        { quote: 'There is extraordinary chemistry that exists in long-term relationships.', author: 'Conrad Levinson, Author Guerilla Marketing series of books' },
        { quote: 'When you help others feel important, you help yourself feel important too.', author: 'David J. Schwartz, Author and Motivational Speaker' },
        { quote: "Don't dwell on what went wrong. Instead, focus on what to do next. Spend your energies on moving forward toward finding the answer.", author: 'Denis Waitley, Author and Motivational Speaker' },
        { quote: 'The single most important thing is to make people happy. If you are making people happy, as a side effect, they will be happy to open up their wallets and pay you.', author: 'Derek Sivers, Founder CD Baby' },
        { quote: 'Customer service is the new marketing.', author: 'Derek Sivers, Founder CD Baby' },
        { quote: 'To give real service you must add something which cannot be bought or measured with money, and that is sincerity and integrity.', author: 'Don Alden Adams, President Watch Tower Bible and Tract Society of Pennsylvania' },
        { quote: 'Our attitude towards others determines their attitude towards us.', author: 'Earl Nightingale, Author and Motivational Speaker' },
        { quote: 'Men are rich only as they give. He who gives great service gets great rewards.', author: 'Elbert Hubbard, Artist and Writer' },
        { quote: 'It is not fair to ask of others what you are not willing to do yourself.', author: 'Eleanor Roosevelt' },
        { quote: 'I like to listen. I have learned a great deal from listening carefully. Most people never listen.', author: 'Ernest Hemingway, Author and Journalist' },
        { quote: 'To listen closely and reply well is the highest perfection we are able to attain in the art of conversation.', author: 'Francois de La Rochefoucauld, Essayist' },
        { quote: "Never take a person's dignity: it is worth everything to them, and nothing to you.", author: 'Fran Barron, Pioneer in Psychology' },
        { quote: "I attract a crowd, not because I'm an extrovert or I'm over the top or I'm oozing with charisma. It's because I care.", author: 'Gary Vaynerchuk, Author "The Thank You Economy"' },
        { quote: 'What do we live for if not to make life less difficult for each other?', author: 'George Eliot, Novelist' },
        { quote: 'Always do more than is required of you.', author: 'George S. Patton' },
        { quote: 'Sometimes one can become lost in a big company and lose sight of how what one does truly helps or impacts the end customer. If you are one of those, think of a fire brigade, a line of people passing buckets of water from one to the other from a source of water to the site of the fire. An individual in the brigade may not be able to see the end result, i.e., the water being thrown on the fire to put it out, but the contribution of the individual is indispensable to the final outcome.', author: 'Grant Bright, Former Project Lead IBM' },
        { quote: 'Happiness is a by-product of an effort to make someone else happy.', author: 'Gretta Palmer, Author and Editor' },
        { quote: 'Quality is remembered long after the price is forgotten.', author: 'Gucci Family Slogan' },
        { quote: 'The world is moved not only by the mighty shoves of the heroes, but also by the aggregate of the tiny pushes of each honest worker.', author: 'Helen Keller, Author and Political Activist' },
        { quote: 'A thousand words will not leave so deep an impression as one deed.', author: 'Henrik Ibsen, Playwrighter and Poet' },
        { quote: 'Our life is frittered away by detail. Simplify, simplify!', author: 'Henry David Thoreau, Author, Poet and Philosopher' },
        { quote: 'Coming together is a beginning. Keeping together is progress. Working together is success.', author: 'Henry Ford, Founder Ford Motor Company' },
        { quote: 'The man who will use his skill and constructive imagination to see how much he can give for a dollar, instead of how little he can give for a dollar, is bound to succeed.', author: 'Henry Ford, Founder Ford Motor Company' },
        { quote: "Don't find fault. Find a remedy.", author: 'Henry Ford, Founder Ford Motor Company' },
        { quote: 'Most people spend more time and energy going around problems than in trying to solve them.', author: 'Henry Ford, Founder Ford Motor Company' },
        { quote: 'We are superior to the competition because we hire employees who work in an environment of belonging and purpose. We foster a climate where the employee can deliver what the customer wants. You cannot deliver what the customer wants by controlling the employee.', author: 'Horst Schulze, Former Ritz Carlton President' },
        { quote: "Employees who are controlled cannot respond caringly; you need superior knowledge and real leadership, not management. Because of this we specifically developed a selection process for leaders; we don't hire managers.", author: 'Horst Schulze, Former Ritz Carlton President' },
        { quote: "Two important things are to have a genuine interest in people and to be kind to them. Kindness, I've discovered, is everything.", author: 'Isaac Bashevis Singer, Author' },
        { quote: 'Courteous treatment will make a customer a walking advertisement.', author: 'James Cash Penney, Founder J.C. Penney Stores' },
        { quote: 'Every great business is built on friendship.', author: 'James Cash Penney, Founder J.C. Penney Stores' },
        { quote: 'I never trust an executive who tends to pass the buck. Nor would I want to deal with him as a customer or a supplier.', author: 'James Cash Penney, Founder J.C. Penney Stores' },
        { quote: 'You cannot improve one thing by 1000% but you can improve 1000 little things by 1%.', author: 'Jan Carlzon, Former CEO of SAS' },
        { quote: 'Repeat business or behavior can be bribed. Loyalty has to be earned.', author: 'Janet Robinson, Former President and CEO of The New York Times Company' },
        { quote: 'One kind word can warm three winter months.', author: 'Japanese Proverb' },
        { quote: 'Trust: The reputation of a thousand years may be undermined by the conduct of one hour.', author: 'Japanese Proverb' },
        { quote: "We see our customers as invited guests to a party, and we are the hosts. It's our job to make the customer experience a little bit better.", author: 'Jeff Bezos, Founder Amazon' },
        { quote: 'A brand for a company is like a reputation for a person. You earn reputation by trying to do hard things well.', author: 'Jeff Bezos, Founder Amazon' },
        { quote: 'Customer satisfaction is worthless. Customer loyalty is priceless.', author: 'Jeffrey Gitomer, Author and Professional Speaker' },
        { quote: "Biggest question: Isn't it really 'customer helping' rather than customer service? And wouldn't you deliver better service if you thought of it that way?", author: 'Jeffrey Gitomer, Author and Professional Speaker' },
        { quote: 'Memorable customer service can only take place in a human-to-human situation.', author: 'Jeffrey Gitomer, Author and Professional Speaker' },
        { quote: "You don't earn loyalty in a day. You earn loyalty day-by-day.", author: 'Jeffrey Gitomer, Author and Professional Speaker' },
        { quote: "Go beyond merely communicating to 'connecting' with people.", author: 'Jerry Bruckner, Author The Success Formula for Personal Growth' },
        { quote: 'There are many who subscribe to the convention that service is a business cost, but our data demonstrates that superior service is an investment that can help drive business growth. Investing in quality talent, and ensuring they have the skills, training and tools that enable them to empathize and actively listen to customers are central to providing consistently excellent service experiences.', author: 'Jim Bush, EVP American Express' },
        { quote: "A rose on time is more valuable than a $1,000 gift that's too late.", author: 'Jim Rohn, Author, Motivational Speaker' },
        { quote: 'If you make a sale, you can make a living. If you make an investment of time and good service in a customer, you can make a fortune.', author: 'Jim Rohn, Author and Motivational Speaker' },
        { quote: 'One of the greatest gifts you can give to anyone is the gift of attention.', author: 'Jim Rohn, Author and Motivational Speaker' },
        { quote: 'If you just communicate, you can get by. But if you communicate skillfully, you can work miracles.', author: 'Jim Rohn, Author and Motivational Speaker' },
        { quote: 'Without caring there can be no quality.', author: 'Joel Barker, Author Future Edge' },
        { quote: 'The smallest good deed is better than the grandest good intention.', author: 'John Burroughs, Author' },
        { quote: 'Quality is never an accident. It is always the result of an intelligent effort.', author: 'John Ruskin, Art Critic and Writer' },
        { quote: 'Make a customer, not a sale.', author: 'Katherine Barchetti, Founder Barchetti Shops' },
        { quote: 'The two most powerful things in existence: a kind word and a thoughtful gesture.', author: 'Kenneth Langone, Venture Capitalist' },
        { quote: 'The most important adage and the only adage is, the customer comes first, whatever the business, the customer comes first.', author: 'Kerry Stokes, Chairman of the Seven Network' },
        { quote: "A lot of people have fancy things to say about customer service, but it's just a day-in, day-out, ongoing, never-ending, persevering, compassionate kind of activity.", author: 'Christopher McCormick, CEO of L.L Bean' },
        { quote: 'Face the simple fact before it comes involved. Solve the small problem before it becomes big.', author: 'Lao Tzu, Philosopher' },
        { quote: 'Do what you say you are going to do, when you say you are going to do it, in the way you said you were going to do it.', author: 'Larry Winget, Author and Motivational Speaker' },
        { quote: "We don't want to push our ideas on to customers, we simply want to make what they want.", author: 'Laura Ashley, Fashion Designer' },
        { quote: 'Our greatest asset is the customer! Treat each customer as if they are the only one!', author: 'Laurice Leitao, Customer Service Professional SeraCare Life Sciences' },
        { quote: 'Your ability to communicate is an important tool in your pursuit of your goals, whether it is with your family, your co-workers or your clients and customers.', author: 'Les Brown, Author and Motivational Speaker' },
        { quote: "If you only talk to a person's head and not their heart people won't listen to you.", author: 'Les Brown, Author and Motivational Speaker' },
        { quote: 'One of the deep secrets of life is that all that is really worth doing is what we do for others.', author: 'Lewis Carroll, Writer and Mathematician' },
        { quote: 'Do right. Do your best. Treat others as you want to be treated.', author: 'Lou Holtz, Football Coach' },
        { quote: 'Words of comfort, skillfully administered, are the oldest therapy known to man.', author: 'Lous Nizer, Lawyer' },
        { quote: 'A customer is the most important visitor on our premises, he is not dependent on us. We are dependent on him. He is not an interruption in our work. He is the purpose of it. He is not an outsider in our business. He is part of it. We are not doing him a favor by serving him. He is doing us a favor by giving us an opportunity to do so.', author: 'Mahatma Gandhi' },
        { quote: 'Service is the rent we pay for being. It is the very purpose of life, and not something you do in your spare time.', author: "Marian Wright Edelman, Founder Children's Defense Fund" },
        { quote: 'Truth builds trust.', author: 'Marilyn Suttle, Success Coach' },
        { quote: "Thank your customer for complaining and mean it. Most will never bother to complain. They'll just walk away.", author: 'Marilyn Suttle, Success Coach' },
        { quote: 'How you think about your customers influences how you respond to them.', author: 'Marilyn Suttle, Success Coach' },
        { quote: 'Always do what is right. It will gratify most of the people, and astound the rest.', author: 'Mark Twain, Author' },
        { quote: "If you don't care, your customer never will.", author: 'Marlene Blaszczyk, Motivational Specialist' },
        { quote: 'Right or wrong, the customer is always right.', author: 'Marshall Field, Founder Marshald Field and Company' },
        { quote: 'The time is always right to do what is right.', author: 'Martin Luther King, Jr.' },
        { quote: 'I’ve learned that people will forget what you said, people will forget what you did, but people will never forget how you made them feel.', author: 'Maya Angelou' },
        { quote: "Every company's greatest assets are its customers, because without customers there is no company.", author: 'Michael LeBoeuf, Business author and former management professor' },
        { quote: 'A satisfied customer is the best business strategy of all.', author: 'Michael LeBoeuf, Business author and former management professor' },
        { quote: 'Bottom-line obsession comes from turning the pursuit of money into a God and forgetting the real master your business serves: The Customer.', author: 'Michael Shevack, Executive Coach' },
        { quote: 'Be kind and merciful. Let no one ever come to you without coming away better and happier.', author: 'Mother Teresa' },
        { quote: 'Remember, the best job goes to the person who can get it done without passing the buck or coming back with excuses.', author: 'Napoleon Hill, Author "Think and Grow Rich"' },
        { quote: 'Great teams need great leaders — leaders who inspire, motivate, instil pride, loyalty and a sense of fun at work.', author: 'Natalie Calvert, Customer Service and Sales Strategist' },
        { quote: 'To understand the man, you must first walk a mile in his moccasin.', author: 'Native American Indian Proverb' },
        { quote: "Nordstrom's Rules for Employees: Rule # 1: Use your good judgment in all situations. There are no additional rules", author: 'Nordstrom Employee Rules' },
        { quote: 'How you think about a problem is more important than the problem itself — so always think positively.', author: 'Norman Vincent Peale, Author The Power of Positive Thinking' },
        { quote: 'The only certain means is to render more and better service than is expected of you, no matter what your task may be.', author: 'Og Mandino, Author The Greatest Salesman in the World' },
        { quote: 'Beginning today, treat everyone you meet as if they were going to be dead by midnight. Extend to them all the care, kindness and understanding you can muster, and do it with no thought of any reward. Your life will never be the same again.', author: 'Og Mandino, Author The Greatest Salesman in the World' },
        { quote: "It is not your customer's job to remember you, it is your obligation and responsibility to make sure they don't have the chance to forget you.", author: 'Patricia Fripp, Professional Keynote Speaker' },
        { quote: "When you make a mistake, there are only three things you should ever do about it: admit it, learn from it, and don't repeat it.", author: 'Bear Bryant, Former College Football Player and Coach' },
        { quote: 'Customers are an investment. Maximize your return.', author: 'PeopleSoft Ad' },
        { quote: "The most important thing in communication is hearing what isn't said.", author: 'Peter Drucker, Best-Selling Author' },
        { quote: "Never underestimate the power of the human element. Whether it's assisting a guest with a special request or a friendly greeting from staff members in the hallway, the people aspect plays a key role in guest satisfaction and loyalty.", author: 'Ramez Faza, Sr. Account Manager at J.D. Power and Associates' },
        { quote: 'Until you understand your customers — deeply and genuinely — you cannot truly serve them.', author: 'Rasheed Ogunlaru, Author of Soul Trader' },
        { quote: "If you work just for money, you'll never make it, but if you love what you're doing and you always put the customer first, success will be yours.", author: "Ray Kroc, Founder McDonald's" },
        { quote: 'The inherent preferences of organizations are clarity, certainty and perfection. The inherent nature of human relationships involves ambiguity, uncertainty, and imperfection. How one honors, balances, and integrates the needs of both is the real trick of feedback.', author: 'Richard Pascale and Anthony Athos, Authors The Art of Japanese Management' },
        { quote: 'People expect good service but few are willing to give it.', author: 'Robert Gately, President Gately Consulting' },
        { quote: 'When the customer comes first, the customer will last.', author: 'Robert Half, Founder of Robert Half International' },
        { quote: 'Inside of every problem lies an opportunity.', author: 'Robert Kiyosaki, Author Rich Dad Poor Dad' },
        { quote: 'There are no traffic jams along the extra mile.', author: 'Roger Staubach, Former NFL Quarterback' },
        { quote: 'Good customer service costs less than bad customer service.', author: 'Sally Gronow, Customer Manager Welsh Water' },
        { quote: 'The goal as a company is to have customer service that is not just the best but legendary.', author: "Sam Walton, Founder Walmart and Sam's club" },
        { quote: "Outstanding leaders go out of their way to boost the self-esteem of their personnel. If people believe in themselves, it's amazing what they can accomplish.", author: "Sam Walton, Founder Walmart and Sam's club" },
        { quote: 'The true measure of a man is how he treats someone who can do him absolutely no good.', author: 'Samuel Johnson, Poet' },
        { quote: 'Politeness goes far, yet costs nothing.', author: 'Samuel Smiles, Author and Reformer' },
        { quote: 'Nothing is so contagious as enthusiasm.', author: 'Samuel Taylor Coleridge, Poet and Philosopher' },
        { quote: "Your best customers leave quite an impression. Do the same, and they won't leave at all.", author: 'SAP Advertising' },
        { quote: "When a customer complains, he is doing you a special favor; he is giving you another chance to serve him to his satisfaction. You will appreciate the importance of this opportunity when you consider that the customer's alternative option was to desert you for a competitor.", author: 'Seymour Fine, Author The Marketing of Ideas and Social Issues' },
        { quote: 'There is a big difference between a satisfied customer and a loyal customer.', author: 'Shep Hyken, Author and Customer Service Speaker' },
        { quote: 'A customer service apology is stronger with a personal touch.', author: 'Shep Hyken, Author and Customer Service Speaker' },
        { quote: 'Treat your employees the way you want your customers treated.', author: 'Shep Hyken, Author and Customer Service Speaker' },
        { quote: 'Follow the customer; if they change — we change.', author: 'Sir Terry Leahy, Former CEO Tesco' },
        { quote: 'Seek first to understand. Then to be understood.', author: 'Stephen Covey, Author "The Seven Habits of Highly Effective People"' },
        { quote: 'Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.', author: 'Steve Jobs, Co-founder Apple' },
        { quote: 'Rule 1: The customer is always right. Rule 2: If the customer is ever wrong, read Rule 1.', author: "Stew Leonard's Grocery Store Customer Policy" },
        { quote: "Be helpful — even if there's no immediate profit in it.", author: 'Susan Ward, Small Business Expert and Writer' },
        { quote: 'Opportunity is missed by most people because it is dressed in overalls and looks like work.', author: 'Thomas Edison, Inventor and Businessman' },
        { quote: 'Solve it. Solve it quickly, solve it right or wrong. If you solve it wrong, it will come back and slap you in the face, and then you can solve it right. Lying dead in the water and doing nothing is a comfortable alternative because it is without risk, but it is an absolutely fatal way to manage a business.', author: 'Thomas Watson, Jr. Former President of IBM and US Ambassador to the Soviet Union' },
        { quote: 'The most valuable of all talents is that of never using two words when one will do.', author: 'Thomas Jefferson' },
        { quote: 'Always strive to provide service above and beyond what the average salesperson would give. It will help you build long-term relationships, trust, and referral business.', author: 'Tom Hopkins, Author How to Master the Art of Selling Anything' },
        { quote: 'The magic formula that successful businesses have discovered is to treat customers like guests and employees like people.', author: 'Tom Peters, Best-Selling Author' },
        { quote: 'Formula for success: under promise and over deliver.', author: 'Tom Peters, Best-Selling Author' },
        { quote: 'All business success rests on something labeled a sale, which at least momentarily weds company and customer.', author: 'Tom Peters, Best-Selling Author' },
        { quote: "Customer service shouldn't just be a department, it should be the entire company.", author: 'Tony Hsieh, CEO Zappos' },
        { quote: "We asked ourselves what we wanted this company to stand for. We didn't want to just sell shoes. I wasn't even into shoes — but I was passionate about customer service.", author: 'Tony Hsieh, CEO Zappos' },
        { quote: "Businesses often forget about the culture, and ultimately, they suffer for it because you can't deliver good service from unhappy employees.", author: 'Tony Hsieh, CEO Zappos' },
        { quote: "Our customers call and e-mail us to say that's how it feels when a Zappos box arrives. And that's how we view this company.", author: 'Tony Hsieh, CEO Zappos' },
        { quote: 'I view my role more as trying to set up an environment where the personalities, creativity and individuality of all the different employees come out and can shine.', author: 'Tony Hsieh, CEO Zappos' },
        { quote: 'Why wait to be memorable?', author: 'Anthony Robbins, Life Coach, Motivational Speaker' },
        { quote: "If we don't take care of our customers, someone else will.", author: 'Unknown' },
        { quote: 'The customer is king.', author: 'Unknown' },
        { quote: "A sale is not something you pursue; it's what happens to you while you are immersed in serving your customer.", author: 'Unknown' },
        { quote: 'The purpose of business should be to make life better for people.', author: 'Unknown' },
        { quote: 'Profit is the applause you get for taking care of your customers and creating a motivating environment for your employees.', author: 'Unknown' },
        { quote: 'Unfortunately, some companies worry more about broken machines than they do broken people.', author: 'Unknown' },
        { quote: "Professionalism: It's NOT the job you DO, It's HOW you DO the job.", author: 'Unknown' },
        { quote: 'A smile is a curve that sets things straight.', author: 'Unknown' },
        { quote: 'Trust is the residue of promises fulfilled.', author: 'Unknown' },
        { quote: 'Swallowing your pride occasionally will never give you indigestion.', author: 'Unknown' },
        { quote: "Treat others the way they want to be treated. (Sometimes called the 'Platinum Rule')", author: 'Unknown' },
        { quote: 'Respect can not be learned, chased or acquired — it can only be earned.', author: 'Unknown' },
        { quote: "Customer service is not a department, it's an attitude!", author: 'Unknown' },
        { quote: 'When eating a fruit, think of the person who planted the tree.', author: 'Vietnamese saying' },
        { quote: 'Do what you do so well that they will want to see it again and bring their friends.', author: 'Walt Disney, Founder Disney' },
        { quote: 'You never get a second chance to make a first impression.', author: 'Will Rogers, Actor' },
        { quote: 'A warm smile is the universal language of kindness.', author: 'William Arthur Ward, Author Fountains of Faith' },
        { quote: 'Success is the ability to go from one failure to another with no loss of enthusiasm.', author: 'Unknown' },
        { quote: 'You can have what you want if you help other people get what they want.', author: 'Zig Ziglar, Author and Motivational Speaker' },
        { quote: "If you love your customer to death, you can't go wrong.", author: 'Graham Day, Lawyer and Corporate Director' },
        { quote: 'You must never actually cheat the customer, even if you can. You must make her happy and satisfied, so she will come back.', author: 'Alexander Turney Stewart, Business Magnate' },
        { quote: 'What helps people, helps business.', author: 'Leo Burnett, Advertising Executive' },
        { quote: 'When a customer enters my store, forget me. He is king.', author: 'John Wanamaker, Merchant, Religious Leader and Political Figure' },
        { quote: 'So I think instead of focusing on the competition, focus on the customer.', author: 'Scott D. Cook, Founder Intuit' },
        { quote: 'Be dramatically willing to focus on the customer at all costs, even at the cost of obsoleting your own stuff.', author: 'Scott D. Cook, Founder Intuit' }
    ];
}
